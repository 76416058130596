import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import { amountUnit } from '../../../../../utils/helper';
import Button from '@mui/material/Button';

function WinGridData({ data, filterRange, cardName, advisorcode, setAdvisorcode, filteredData }) {
    let apiData = data;
    const colname = (values) => {
        if (apiData?.columnname.some(colData => colData.colname === values)) {
            const getDisplayName = apiData?.columnname.find(colData => colData.colname === values);
            return getDisplayName?.displayName;
        }
    }

    const [currentPage, setCurrentPage] = useState(0);
    const recordsPerPage = 4;
    const totalPages = Math.ceil(filteredData?.length / recordsPerPage);

    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const currentRecords = filteredData?.slice(
        currentPage * recordsPerPage,
        (currentPage + 1) * recordsPerPage
    );

    const handleAdvisorClick = (code, name) => {
        const drillDown = localStorage.getItem("drillDown");
        const dataArray = drillDown ? JSON.parse(drillDown) : []
        const currDrillDown = { "code": code, "name": name }
        const newArray = [...dataArray, currDrillDown];
        localStorage.setItem("drillDown", JSON.stringify(newArray))
        const getLength = newArray.length - 1;
        setAdvisorcode(newArray[getLength].code)
        window.scrollTo(0, 0)
    }


    return (
        <div className='lyt-grid-table'>
            {currentRecords?.map((item, index) => (
                <Accordion className='bs-table-accordion' defaultExpanded>
                    <AccordionSummary
                        expandIcon={<span className='icon icon-chevron-down'></span>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <ul className='acc-list'>
                            <li className='acc-item' >
                                <span className='pair'>{item.employeename}</span>
                            </li>
                        </ul>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul className='acc-list'>
                            {Object.entries(item).map(([key, value], i) => {
                                switch (cardName.toLowerCase().replaceAll(' ', '')) {
                                    case "ape":
                                        return (
                                            (key === "apeplan" || key === "apeactuals" || key === "apeach%") && (
                                                <li className='acc-item' key={i}>
                                                    <span className='key'>{colname(key)}</span>
                                                    <span className='pair'>{key === "apeach%" ? <div className={`mod-tag ${value <= 85 ? "down" : ""} ${value > 85 && value < 95 ? "medium" : ""}`}>
                                                        <span className='value'>
                                                            {value}%
                                                        </span>
                                                        <span className='icon icon-trending'></span>
                                                    </div> : amountUnit(value, filterRange)}</span>
                                                </li>
                                            )
                                        );
                                    case "wfyp":
                                        return (
                                            (key === "wfypplan" || key === "wfypactuals" || key === "wfypach%") && (
                                                <li className='acc-item' key={i}>
                                                    <span className='key'>{colname(key)}</span>
                                                    <span className='pair'>{key === "wfypach%" ? <div className={`mod-tag ${value <= 85 ? "down" : ""} ${value > 85 && value < 95 ? "medium" : ""}`}>
                                                        <span className='value'>
                                                            {value}%
                                                        </span>
                                                        <span className='icon icon-trending'></span>
                                                    </div> : amountUnit(value, filterRange)}</span>
                                                </li>
                                            )
                                        );
                                    case "cp":
                                        return (
                                            (key === "cpplan" || key === "cpactuals" || key === "cpach%") && (
                                                <li className='acc-item' key={i}>
                                                    <span className='key'>{colname(key)}</span>
                                                    <span className='pair'>{key === "cpach%" ? <div className={`mod-tag ${value <= 85 ? "down" : ""} ${value > 85 && value < 95 ? "medium" : ""}`}>
                                                        <span className='value'>
                                                            {value}%
                                                        </span>
                                                        <span className='icon icon-trending'></span>
                                                    </div> : amountUnit(value, filterRange)}</span>
                                                </li>
                                            )
                                        );
                                    case "nop":
                                        return (
                                            (key === "nopplan" || key === "nopactuals" || key === "nopach%") && (
                                                <li className='acc-item' key={i}>
                                                    <span className='key'>{colname(key)}</span>
                                                    <span className='pair'>{key === "nopach%" ? <div className={`mod-tag ${value <= 85 ? "down" : ""} ${value > 85 && value < 95 ? "medium" : ""}`}>
                                                        <span className='value'>
                                                            {value}%
                                                        </span>
                                                        <span className='icon icon-trending'></span>
                                                    </div> : Number(value) ? Number(value).toLocaleString() : value}</span>
                                                </li>
                                            )
                                        );
                                    case "ats":
                                        return (
                                            (key === "atsplan" || key === "atsactuals" || key === "atsach%") && (
                                                <li className='acc-item' key={i}>
                                                    <span className='key'>{colname(key)}</span>
                                                    <span className='pair'>{key === "atsach%" ? <div className={`mod-tag ${value <= 85 ? "down" : ""} ${value > 85 && value < 95 ? "medium" : ""}`}>
                                                        <span className='value'>
                                                            {value}%
                                                        </span>
                                                        <span className='icon icon-trending'></span>
                                                    </div> : Number(value) ? Number(value).toLocaleString() : value}</span>
                                                </li>
                                            )
                                        );
                                    case "bomhc":
                                        return (
                                            (key === "bomhcplan" || key === "bomhcactuals" || key === "bomhcach%") && (
                                                <li className='acc-item' key={i}>
                                                    <span className='key'>{colname(key)}</span>
                                                    <span className='pair'>{key === "bomhcach%" ? <div className={`mod-tag ${value <= 85 ? "down" : ""} ${value > 85 && value < 95 ? "medium" : ""}`}>
                                                        <span className='value'>
                                                            {value}%
                                                        </span>
                                                        <span className='icon icon-trending'></span>
                                                    </div> : Number(value) ? Number(value).toLocaleString() : value}</span>
                                                </li>
                                            )
                                        );
                                    case "attrition%":
                                        return (
                                            (key === "attritionfls" || key === "attrition%") && (
                                                <li className='acc-item span2' key={i}>
                                                    <span className='key'>{colname(key)}</span>
                                                    <span className='pair'>{key === "attrition%" ? <div className={`mod-tag ${value <= 85 ? "down" : ""} ${value > 85 && value < 95 ? "medium" : ""}`}>
                                                        <span className='value'>
                                                            {value}%
                                                        </span>
                                                        <span className='icon icon-trending'></span>
                                                    </div> : Number(value) ? Number(value).toLocaleString() : value}</span>
                                                </li>
                                            )
                                        );
                                    case "netgain":
                                        return (
                                            (key === "newjoinees" || key === "netgain" || key === "attritionfls") && (
                                                <li className='acc-item' key={i}>
                                                    <span className='key'>{colname(key)}</span>
                                                    <span className='pair'>{Number(value) ? Number(value).toLocaleString() : value}</span>
                                                </li>
                                            )
                                        );
                                    default:
                                        return null;
                                }
                            })}
                            {item.employeename == "Total" || item.employeename == "RD" || item.employeename == "Loyalty" ?
                                <></> :
                                <li className='acc-item action-item'>
                                    <Button className="btn btn-link" onClick={() => handleAdvisorClick(item.employeecode, item.employeename)}>View</Button>
                                </li>
                            }

                        </ul>
                    </AccordionDetails>
                </Accordion>

            ))
            }
            <div className='pagination-wrap'>
                <div className='left-wrap'>
                    <IconButton disableRipple className='btn btn-search' onClick={handlePrev} disabled={currentPage === 0}>
                        <span className='icon icon-prev'></span>
                    </IconButton>
                    <IconButton disableRipple className='btn btn-search' onClick={handleNext} disabled={currentPage === totalPages - 1}>
                        <span className='icon icon-next'></span>
                    </IconButton>
                </div>
                <div className='right-wrap'>
                    <span>Page</span>
                    <span>{currentPage + 1}</span>
                    <span>of {totalPages}</span>
                </div>
            </div>
        </div >

    )
}

export default WinGridData