import React, { useState, useEffect } from 'react';
import Loader from '../../../common/components/Loader'
import logo from '../../../common/assets/images/logo.svg'
import irisLogo from '../../../common/assets/images/iris-logo.svg'
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import axios from 'axios';
import { useFetchData } from '../../../hooks/common/useApiService';
import { encryptedData } from '../../../utils/encrypt';
import AuthenticateByToken from '../../AuthenticateByToken/view';
import vapdUser from '../../../common/json/vapdUser.json'

function Login() {
    const fetchData = useFetchData();

    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState("")
    const location = window.location.search;
    const params = new URLSearchParams(location);
    const urlToken = params.get('token');

    const validateVapdUser = (values) => {
        const checkVapdUser = vapdUser.some(user => user.username === values.username && user.password === values.password && user.user_type === values.usertype)
        if (checkVapdUser) {
            const findVapdUser = vapdUser.find(user => user.username === values.username && user.password === values.password && user.user_type === values.usertype)
            return { isCheck: true, empcode: findVapdUser?.empcode, department: findVapdUser?.department }
        }
    }

    const departmentArray = [
        "Agency",
        "BANCA (AU)",
        "COB (Saraswat)",
        "Direct Sales (Loyalty)",
        "Direct Sales (Retail)",
        "Direct Sales (Women)",
        "PD (BFL)",
        "PD (CORE)",
        "PD (CORP)",
        "PD (IMF)",
        "PD (NIFLX Sales)",
        "PD (PROSALES)",
        "PD (RBHD)",
        "PD (RGBL)",
        "PD (SQYD)",
        "Distributor",
        "Distributor Others",
        "Head - Direct Sales And Retail Assurance",
        "Partnership Distribution",
        "COB (Others)",
        "Bancassurance (Saraswat)",
        "PD (OTHERS)",
        "Bancassurance (AU)",
        "Banca (Core)",
        "PD And Banca",
        "PD (HND)"
    ]

    const validationSchema = Yup.object({
        usertype: Yup.string()
            .required('Usertype is required'),
        username: Yup.string()
            .required('Username is required'),
        password: Yup.string()
            .required('Password is required'),
    });

    const [showPassword, setShowPassword] = React.useState(false);
    const [loading, setLoading] = useState(true);
    const [loginLoader, setLoginLoader] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    const navigate = useNavigate();

    const fetchLoginLogs = (usertype, username) => {
        const logData = JSON.stringify({ "token": "post_to_bigquery" });
        const loginHeaders = {
            'x-token-key': 'BZz8VIdg39p0OxT',
            'Ocp-Apim-Subscription-Key': '8adcdc820a9c4e408ff4e26a43224427',
            'Content-Type': 'application/json'
        };
        fetchData('post', 'gcptoken/token-generation', logData, loginHeaders)
            .then((tokenResponse) => {
                const logToken = tokenResponse.data?.token;
                const logRecord = async () => {
                    try {
                        const ipResponse = await fetch('https://api.ipify.org?format=json');
                        const ipData = await ipResponse.json();
                        const ipAddress = ipData.ip;
                        const dateTime = new Date().toUTCString();
                        const userAgent = navigator.userAgent;
                        const screenResolution = `${window.screen.width}x${window.screen.height}`;
                        const language = navigator.language;
                        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        const isTouchDevice = navigator.maxTouchPoints > 0 ? 'true' : 'false';
                        const deviceOrientation = window.screen.orientation.type;
                        const connectionType = navigator.connection ? navigator.connection.effectiveType : 'unknown';
                        const deviceMemory = navigator.deviceMemory || 'unknown';
                        const cpuCores = navigator.hardwareConcurrency || 'unknown';

                        const payload = {
                            User_Type: usertype,
                            Employee_Code: username,
                            Agent_Code: localStorage.getItem('userId'),
                            IP_Address: ipAddress,
                            Date_Time: dateTime,
                            User_Agent: userAgent,
                            Screen_Resolution: screenResolution,
                            Language: language + "| Prod",
                            Time_Zone: timeZone,
                            Is_Touch_Device: isTouchDevice,
                            Device_Orientation: deviceOrientation,
                            Connection_Type: connectionType,
                            Device_Memory: deviceMemory,
                            CPU_Cores: cpuCores,
                        };
                        const headers = {
                            'Content-Type': 'application/json',
                            'Ocp-Apim-Subscription-Key': '8adcdc820a9c4e408ff4e26a43224427',
                            'Authorization': `Bearer ${logToken}`,
                        };
                        await fetch('https://fgliserviceprod.fglife.in/gcp/iris_logs', {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(payload),
                        });
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                }
                logRecord();
            })
    }
    const handleLogin = async (values) => {
        setLoginLoader(true)
        const isValidVapdUser = validateVapdUser(values)
        const username = isValidVapdUser?.isCheck ? isValidVapdUser?.empcode : values.username
        const usertype = values.usertype
        const encryptedUserId = encryptedData(values.username)
        const encryptedPassword = encryptedData(values.password)
        if (isValidVapdUser?.isCheck) {
            const filterArray = departmentArray?.some((elem) => elem === isValidVapdUser?.department)

            if (filterArray) {
                let data = JSON.stringify({
                    "Type": usertype === "Agent" ? "2" : usertype === "Partner" ? "3" : "1",
                    "Code": username
                });
                let secondConfig = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://fgliserviceprod.fglife.in/AgentAPIExternal/AgentDetail/AgentDetailVW',
                    headers: {
                        'Content-Type': 'application/json',
                        'Ocp-Apim-Subscription-Key': 'f519a06702f34732a30584456a8f8957',
                        'Authorization': 'Basic aGx1c2VyOmgzckAxOTcwZy5D',
                        'IR_USERID': 'webservice_user'
                    },
                    data: data
                };
                axios.request(secondConfig)
                    .then((secondResponse) => {
                        if (!secondResponse.data.Message) {
                            localStorage?.setItem("userId", secondResponse.data.AgentCode)
                            localStorage?.setItem("username", secondResponse.data.AgentName)
                            localStorage?.setItem("userChannel", secondResponse.data.Channel)
                            localStorage?.setItem("usertype", usertype)
                            fetchLoginLogs(usertype, username)
                            setLoginLoader(false);
                            navigate("/dashboard");
                        } else {
                            const newData = JSON.stringify({ "token": "prxy_mapping" });
                            const newHeaders = {
                                'x-token-key': 'BZz8VIdg39p0OxT',
                                'Ocp-Apim-Subscription-Key': '8adcdc820a9c4e408ff4e26a43224427',
                                'Content-Type': 'application/json',
                                // "agent_id": advisorcode
                            };
                            fetchData('post', 'gcptoken/token-generation', newData, newHeaders)
                                .then((response) => {
                                    const tokenProxy = response.data?.token
                                    const payloadData = JSON.stringify({
                                        "employee_code": username
                                    });
                                    fetchData('post', 'gcp/iris_proxy', payloadData, null, tokenProxy)
                                        .then((proxyResponse) => {
                                            if (proxyResponse.data[0].statusCode === "200") {
                                                setLoading(false);
                                                localStorage?.setItem("userId", proxyResponse.data[0].response.prxy_mapping_details[0].agentid)
                                                localStorage?.setItem("username", proxyResponse.data[0].response.prxy_mapping_details[0].agentname)
                                                localStorage?.setItem("userChannel", proxyResponse.data[0].response.prxy_mapping_details[0].channel)
                                                localStorage?.setItem("usertype", usertype)
                                                fetchLoginLogs(usertype, username)
                                                setLoginLoader(false);
                                                navigate("/dashboard");
                                            } else {
                                                setLoginLoader(false);
                                                setIsValid(false);
                                                setErrorMessage("Unauthorised Access")
                                                setTimeout(() => {
                                                    setIsValid(true);
                                                }, 5000);
                                            }

                                        })
                                })
                        }
                    })
            } else {
                const newData = JSON.stringify({ "token": "prxy_mapping" });
                const newHeaders = {
                    'x-token-key': 'BZz8VIdg39p0OxT',
                    'Ocp-Apim-Subscription-Key': '8adcdc820a9c4e408ff4e26a43224427',
                    'Content-Type': 'application/json',
                    // "agent_id": advisorcode
                };
                fetchData('post', 'gcptoken/token-generation', newData, newHeaders)
                    .then((response) => {
                        const tokenProxy = response.data?.token
                        const payloadData = JSON.stringify({
                            "employee_code": username
                        });
                        fetchData('post', 'gcp/iris_proxy', payloadData, null, tokenProxy)
                            .then((proxyResponse) => {
                                if (proxyResponse.data[0].statusCode === "200") {
                                    setLoading(false);
                                    localStorage?.setItem("userId", proxyResponse.data[0].response.prxy_mapping_details[0].agentid)
                                    localStorage?.setItem("username", proxyResponse.data[0].response.prxy_mapping_details[0].agentname)
                                    localStorage?.setItem("userChannel", proxyResponse.data[0].response.prxy_mapping_details[0].channel)
                                    localStorage?.setItem("usertype", usertype)
                                    fetchLoginLogs(usertype, username)
                                    setLoginLoader(false);
                                    navigate("/dashboard");
                                } else {
                                    setLoginLoader(false);
                                    setIsValid(false);
                                    setErrorMessage("Unauthorised Access")
                                    setTimeout(() => {
                                        setIsValid(true);
                                    }, 5000);
                                }

                            })
                    })
            }
        }
        else {
            const data = JSON.stringify({
                "source": "OnboardingJourney",
                "merchantSalt": "U8InIxKzxqUr4xS0F/Zm8VEk5Cdlrt1BZ9C8C+7XeaA46qkFMa+0MWSaGf3gu9ui",
                // "merchantSalt": "/JZtOeIg9t/K+3cT0BQWW0v/rFUAc2E7muH9jY3OKnwQhU3QPE648icTHj00GEM9",
                "userID": encryptedUserId,
                "password": encryptedPassword
            });
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://fglicommonservices.azure-api.net/ADAuthentication_V1/api/v1/Login/AuthenticateUser_V1',
                // url: 'https://lfagentapigw-rsg.azure-api.net/ADAuthentication_V1/api/v1/Login/AuthenticateUser_V1',
                headers: {
                    'Ocp-Apim-Subscription-Key': '19a3f219e739470dabf24e017247d89c',
                    // 'Ocp-Apim-Subscription-Key': '42fbe81f5c824540a20d1895aae9c0c5',
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios.request(config)
                .then((response) => {
                    if (response.data.isSuccess) {
                        const filterArray = departmentArray?.some((elem) => elem === response.data.responseObject.department)
                        if (filterArray) {
                            let data = JSON.stringify({
                                "Type": usertype === "Agent" ? "2" : usertype === "Partner" ? "3" : "1",
                                "Code": username
                            });
                            let secondConfig = {
                                method: 'post',
                                maxBodyLength: Infinity,
                                url: 'https://fgliserviceprod.fglife.in/AgentAPIExternal/AgentDetail/AgentDetailVW',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Ocp-Apim-Subscription-Key': 'f519a06702f34732a30584456a8f8957',
                                    'Authorization': 'Basic aGx1c2VyOmgzckAxOTcwZy5D',
                                    'IR_USERID': 'webservice_user'
                                },
                                data: data
                            };
                            axios.request(secondConfig)
                                .then((secondResponse) => {
                                    if (!secondResponse.data.Message) {
                                        localStorage?.setItem("userId", secondResponse.data.AgentCode)
                                        localStorage?.setItem("username", secondResponse.data.AgentName)
                                        localStorage?.setItem("userChannel", secondResponse.data.Channel)
                                        localStorage?.setItem("usertype", usertype)
                                        fetchLoginLogs(usertype, username)
                                        setLoginLoader(false);
                                        navigate("/dashboard");
                                    } else {
                                        const newData = JSON.stringify({ "token": "prxy_mapping" });
                                        const newHeaders = {
                                            'x-token-key': 'BZz8VIdg39p0OxT',
                                            'Ocp-Apim-Subscription-Key': '8adcdc820a9c4e408ff4e26a43224427',
                                            'Content-Type': 'application/json',
                                            // "agent_id": advisorcode
                                        };
                                        fetchData('post', 'gcptoken/token-generation', newData, newHeaders)
                                            .then((response) => {
                                                const tokenProxy = response.data?.token
                                                const payloadData = JSON.stringify({
                                                    "employee_code": username
                                                });
                                                fetchData('post', 'gcp/iris_proxy', payloadData, null, tokenProxy)
                                                    .then((proxyResponse) => {
                                                        if (proxyResponse.data[0].statusCode === "200") {
                                                            setLoading(false);
                                                            localStorage?.setItem("userId", proxyResponse.data[0].response.prxy_mapping_details[0].agentid)
                                                            localStorage?.setItem("username", proxyResponse.data[0].response.prxy_mapping_details[0].agentname)
                                                            localStorage?.setItem("userChannel", proxyResponse.data[0].response.prxy_mapping_details[0].channel)
                                                            localStorage?.setItem("usertype", usertype)
                                                            fetchLoginLogs(usertype, username)
                                                            setLoginLoader(false);
                                                            navigate("/dashboard");
                                                        } else {
                                                            setLoginLoader(false);
                                                            setIsValid(false);
                                                            setErrorMessage("Unauthorised Access")
                                                            setTimeout(() => {
                                                                setIsValid(true);
                                                            }, 5000);
                                                        }

                                                    })
                                            })
                                    }
                                })
                        } else {
                            const newData = JSON.stringify({ "token": "prxy_mapping" });
                            const newHeaders = {
                                'x-token-key': 'BZz8VIdg39p0OxT',
                                'Ocp-Apim-Subscription-Key': '8adcdc820a9c4e408ff4e26a43224427',
                                'Content-Type': 'application/json',
                                // "agent_id": advisorcode
                            };
                            fetchData('post', 'gcptoken/token-generation', newData, newHeaders)
                                .then((response) => {
                                    const tokenProxy = response.data?.token
                                    const payloadData = JSON.stringify({
                                        "employee_code": username
                                    });
                                    fetchData('post', 'gcp/iris_proxy', payloadData, null, tokenProxy)
                                        .then((proxyResponse) => {
                                            if (proxyResponse.data[0].statusCode === "200") {
                                                setLoading(false);
                                                localStorage?.setItem("userId", proxyResponse.data[0].response.prxy_mapping_details[0].agentid)
                                                localStorage?.setItem("username", proxyResponse.data[0].response.prxy_mapping_details[0].agentname)
                                                localStorage?.setItem("userChannel", proxyResponse.data[0].response.prxy_mapping_details[0].channel)
                                                localStorage?.setItem("usertype", usertype)
                                                fetchLoginLogs(usertype, username)
                                                setLoginLoader(false);
                                                navigate("/dashboard");
                                            } else {
                                                setLoginLoader(false);
                                                setIsValid(false);
                                                setErrorMessage("Unauthorised Access")
                                                setTimeout(() => {
                                                    setIsValid(true);
                                                }, 5000);
                                            }

                                        })
                                })
                        }
                    } else {
                        setLoginLoader(false);
                        setIsValid(false);
                        setErrorMessage("Invalid Credentials")
                        setTimeout(() => {
                            setIsValid(true);
                        }, 5000);
                    }
                })
        }
    };
    return (
        <>

            {urlToken ?
                <AuthenticateByToken urlToken={urlToken} departmentArray={departmentArray} />
                :
                loading ? (
                    <Loader />
                ) : (
                    <div className='lyt-login'>
                        <div className='login-wrap'>
                            <div className='head-wrap'>
                                <div className='logo-wrap'>
                                    <img src={logo} alt='logo' />
                                </div>
                                <div className='title-wrap'>
                                    <p className='text'>Welcome to</p>
                                    <h2 className='title'>Sales Analytics Tool <span className='text'>v2.2.0</span></h2>
                                </div>
                            </div>
                            <div className='form-wrap'>
                                <div className='bs-form'>
                                    <Formik
                                        initialValues={{ usertype: 'Agent', username: '', password: '' }}
                                        validationSchema={validationSchema}
                                        // onSubmit={(values) => {
                                        //     console.log(values);
                                        // }}
                                        onSubmit={handleLogin}
                                    >
                                        {({ errors, touched, values, handleChange, handleBlur }) => (

                                            <Form>
                                                <Grid
                                                    container
                                                    rowSpacing={{ md: 3, xs: 2.5 }}
                                                    columnSpacing={{ md: 3, xs: 4 }}
                                                >
                                                    <Grid item md={12} sm={12} xs={12} className='cm-hidden-md'>
                                                        <div className='bottom-logo-wrap'>
                                                            <img src={irisLogo} alt='' />
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={12} sm={12} xs={12}>
                                                        <div className='form-group'>
                                                            <div className='bs-select typ-sm'>
                                                                <FormControl fullWidth>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="usertype"
                                                                        name='usertype'
                                                                        value={values.usertype}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        IconComponent={(props) => (
                                                                            <span
                                                                                {...props}
                                                                                className={`icon-chevron-down ${props.className}`}
                                                                            ></span>
                                                                        )}
                                                                        MenuProps={{
                                                                            classes: {
                                                                                paper: "bs-menu-dropdown",
                                                                            },
                                                                        }}
                                                                        startAdornment={
                                                                            <InputAdornment position="start">
                                                                                <span className='icon icon-agent'></span>
                                                                            </InputAdornment>
                                                                        }

                                                                    >
                                                                        <MenuItem value="Agent">Agent / IM</MenuItem>
                                                                        <MenuItem value="Employee">Employee</MenuItem>
                                                                        <MenuItem value="Partner">Partner</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText>{touched.usertype && errors.usertype ? errors.usertype : ''}</FormHelperText>

                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={12} sm={12} xs={12}>
                                                        <div className='form-group'>
                                                            <TextField
                                                                autoComplete="off"
                                                                id="username"
                                                                name='username'
                                                                variant="outlined"
                                                                className='form-control'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={touched.username && Boolean(errors.username)}
                                                                helperText={touched.username && errors.username}
                                                                placeholder='Enter user ID'
                                                                InputProps={{
                                                                    startAdornment: <span className='icon icon-login-user'></span>,
                                                                }}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={12} sm={12} xs={12}>
                                                        <div className='form-group'>
                                                            <TextField
                                                                autoComplete="off"
                                                                id="password"
                                                                name="password"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={touched.password && Boolean(errors.password)}
                                                                helperText={touched.password && errors.password}
                                                                variant="outlined"
                                                                className='form-control'
                                                                type={showPassword ? 'text' : 'password'}
                                                                placeholder='Enter password'
                                                                InputProps={{
                                                                    startAdornment: <span className='icon icon-password'></span>,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={handleClickShowPassword}
                                                                                onMouseDown={handleMouseDownPassword}
                                                                                edge="end"
                                                                            >
                                                                                {showPassword ? (<span className='icon icon-password-show'></span>) : (<span className='icon icon-password-hide'></span>)}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={12} sm={12} xs={12}>
                                                        <div className='bottom-wrap'>
                                                            <Button type="submit" className="btn btn-default">LOGIN SECURELY</Button>

                                                            {!isValid ?
                                                                <p className="error-message">{errorMessage}</p> : ''
                                                            }
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        <div className='bottom-logo-wrap cm-hidden-sm'>
                            <img src={irisLogo} alt='' />
                        </div>
                        <p className='floating-text cm-hidden-sm'>IRIS</p>
                        {loginLoader &&
                            <div className='loader-wrap opaque'>
                                <div className="img-wrap">
                                    <div className="loader"></div>
                                    <span className="text">Loading...</span>
                                </div>
                            </div>
                        }

                    </div>
                )
            }
        </>
    )
}

export default Login