import React, { useState, useEffect } from 'react';
import useSize from '../../../../../hooks/common/useSize'
import loadIcon from '../../../../../common/assets/images/favicon.png'
import loadingImg from '../../../../../common/assets/images/three-dots.svg'
import CompanyGridData from '../CompanyGridData';
import { useFetchData } from '../../../../../hooks/common/useApiService';
import { dateRangeFormat } from '../../../../../hooks/common/useDateRangeFormat';
import Button from '@mui/material/Button';
import { MaterialReactTable } from 'material-react-table';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as XLSX from 'xlsx';
import 'jspdf-autotable';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { formatValue, amountUnit } from '../../../../../utils/helper';

function CompanyPremiumTable({ cardName, filterRange, advisorcode, setAdvisorcode, dateValue, token, handleCompAdvisorClick }) {
    const fetchData = useFetchData();
    const winDimensions = useSize();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [mergeArr, setMergeArr] = useState([]);

    useEffect(() => {
        var payloadData = JSON.stringify({
            "start_date": dateRangeFormat(dateValue[0]),
            "end_date": dateRangeFormat(dateValue[1]),
            "agent_id": advisorcode
        });

        setLoading(true)


        fetchData('post', process.env.REACT_APP_LOGIN_DETAILS_COMPANY, payloadData, null, token)?.then((response) => {
            var cardData = response.data
            if (cardName.toLowerCase() === "premium") {
                cardData = response.data.ape
            } else {
                cardData = response.data[`${cardName.toLowerCase().replaceAll(' ', '')}`]
            }
            var detailsArr = cardData?.details;
            var footerArr = cardData?.footer;
            var mergeDetails = [...detailsArr, ...footerArr]
            setMergeArr(mergeDetails)
            setData(cardData)
            setFilteredData(mergeDetails);
            setLoading(false)
        })
    }, [cardName, advisorcode, dateValue, token]);

    const colData = (data?.columnname ?? []).map(col => {
        if (col && col.colname &&
            col.colname !== 'id'
        ) {
            return {
                accessorKey: col.colname,
                header: col.displayName,
                muiTableHeadCellProps: {
                    className: col.colname.includes('mtd') ? "mtd-class" : col.colname.includes('ytd') ? "ytd-class" : '', // Add your custom class
                },

                size: col.dWidth,
                Cell: (props) => {
                    if (cardName.toLowerCase() === "premium" || cardName.toLowerCase() === "ape" || cardName.toLowerCase() === "cp" || cardName.toLowerCase().replaceAll(' ', '') === "productmix-ape") {
                        if (col.colname.includes("%")) {
                            return <div className={`mod-tag ${props.cell.getValue() <= 85 ? "down" : ""} ${props.cell.getValue() > 85 && props.cell.getValue() < 95 ? "medium" : ""}`}>
                                <span className='value'>{formatValue(props.cell.getValue())}%</span>
                                <span className='icon icon-trending'></span>
                            </div>
                        } else {
                            if (col.colname === 'agentname' || col.colname === 'channel' || col.colname === 'agentcode') {
                                return props.cell.getValue();
                            } else {
                                return amountUnit(props.cell.getValue(), filterRange)
                            }
                        }
                    } else {
                        if (col.colname === 'agentname' || col.colname === 'channel' || col.colname === 'agentcode') {
                            return props.cell.getValue();
                        } else {
                            return formatValue(props.cell.getValue())
                        }
                    }
                }
            };
        }
        return null;
    }).filter(Boolean);
    colData.push({
        accessorKey: 'action',
        header: 'Action',
        size: 150,
        Cell: ({ cell, row }) => (
            row.original.channel == "Group NB" || row.original.channel == "Total Retail" || row.original.channel == "Grand Total" ? <></> :
                <Button className="btn btn-link" onClick={() => handleCompAdvisorClick(row.original.agentcode, row.original.agentname, row.original.channel)}>View</Button>
        ),

    });

    const handleExport = (format) => {
        if (format === 'excel') {
            const ws = XLSX.utils.json_to_sheet(data?.details);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Data');
            XLSX.writeFile(wb, 'data.xlsx');
        }
    };
    const [searchTerm, setSearchTerm] = useState("");
    const [searchShow, setSearchShow] = React.useState(false)
    const [grantTotal, setGrandTotal] = React.useState(['Grand Total'])

    const handleFilterSearch = (event, value) => {
        setSearchTerm(value);
        if (value === "") {
            setFilteredData(mergeArr || []);
        } else {
            const lowercasedValue = value.toLowerCase();
            const filtered = mergeArr.filter((item) =>
                Object.keys(item).some((key) =>
                    item[key]?.toString().toLowerCase().includes(lowercasedValue)
                )
            );
            const filterValues = filtered?.filter((elem) => elem?.channel === "Grand Total");
            if (filterValues?.length > 0) {
                setGrandTotal(['Grand Total'])
            } else {
                setGrandTotal([])
            }
            setFilteredData(filtered);
        }
    };

    const handleSearchClear = () => {
        setSearchTerm("")
        setFilteredData(mergeArr || []);
        setSearchShow(false)
        setGrandTotal(['Grand Total'])
    }
    const handleSearch = () => {
        setSearchShow(true)
    }
    return (
        <>
            <div className={`bs-panel typ-table typ-auto ${data?.headingcolour} ${data?.bgcolor}`}>
                <div className='panel-head'>
                    <div className='left-wrap'>
                        <h2 className='p-title'>{data?.name}</h2>
                    </div>
                    <div className='right-wrap'>
                        <div className={`bs-search  ${searchShow ? 'active' : ''}`}>
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                open={false}
                                value={searchTerm}
                                options={filteredData?.flatMap(Object.values).map(value => value.toString())}
                                onInputChange={handleFilterSearch}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        InputProps={{
                                            ...params.InputProps,

                                            endAdornment: (
                                                <InputAdornment position="end" onClick={handleSearchClear}>
                                                    <span className={`icon icon-${searchTerm === "" ? "search" : "close"}`}></span>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder='Search' />
                                )}
                                classes={{ paper: 'bs-menu-dropdown' }}

                            />
                        </div>
                        <div className='bs-select typ-sm typ-toggle cm-hidden-sm'>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value=""
                                    renderValue={(selected) => "Export"}
                                    onChange={(e) => handleExport(e.target.value)}
                                    IconComponent={(props) => (
                                        <span
                                            {...props}
                                            className={`icon-chevron-down ${props.className}`}
                                        ></span>
                                    )}
                                    MenuProps={{
                                        classes: {
                                            paper: "bs-menu-dropdown",
                                        },
                                    }}
                                    displayEmpty
                                >
                                    <MenuItem value="excel">Export to Excel</MenuItem>
                                    {/* <MenuItem value="pdf">Export to PDF</MenuItem> */}
                                </Select>
                            </FormControl>
                        </div>

                        <div className='cm-hidden-md'>
                            <IconButton disableRipple className='btn btn-search' onClick={handleSearch}>
                                <span className='icon icon-search'></span>
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div className='panel-content' >
                    {winDimensions[0] <= 768 ?
                        (
                            loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (
                                <CompanyGridData data={data} filterRange={filterRange} cardName={cardName} advisorcode={advisorcode} setAdvisorcode={setAdvisorcode} filteredData={filteredData} handleCompAdvisorClick={handleCompAdvisorClick} />
                            )
                        ) : (
                            loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (
                                <div className="bs-pinned-table">

                                    <MaterialReactTable
                                        data={filteredData}
                                        columns={colData}
                                        getRowId={(row) => row?.channel}

                                        initialState={{
                                            columnVisibility: { Pin: false },
                                            rowPinning: {
                                                bottom: [],
                                                top: grantTotal,
                                                // top: [],

                                            },
                                        }}
                                        enableColumnActions={false}
                                        enableTopToolbar={false}
                                        enableBottomToolbar={false}
                                        enableFiltering={false}
                                        enableSorting={false}
                                        enableRowPinning={grantTotal?.length > 0 && filteredData?.length > 0 ? true : false}
                                        enablePagination={false}
                                        enableStickyHeader={true}
                                        enableRowActions={false}
                                        enableRowSelection={false}
                                    />


                                    {/* <MaterialReactTable table={table} /> */}
                                </div>
                            )
                        )}

                </div>
            </div>
        </>
    )
}

export default CompanyPremiumTable